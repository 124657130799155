<template>

  <div class="flex justify-end bg-white rounded-md p-2 mt-2">
    <div class="crear flex justify-end" style="min-width: 244px">
      <Button
        label="Crear un nueva solicitud"
        class="bg-blue-900"
        @click="$router.push({name:'pharmasan.administrativa.juridica.solicitudes.crear', query: { base: route.params.id, tipo: 5, provisionalidad: false }})"/>
    </div>
  </div>
</template>

<script>
import { useRoute } from 'vue-router'
export default {
  name: 'SolicitudesHeaderDerecho',
  setup () {
    const route = useRoute()
    return { route }
  }
}
</script>

<style scoped>

</style>
